<template>
  <div class="flex flex-col w-full sm:ml-6 sm:mt-0  " v-if="selectedEpreuveType">

    <!-- Title and buttons-->
    <div class="inline-flex justify-between items-center">
      <div class="mb-2">
        <p v-if="!addExam" class="font-semibold text-sm text-gray-800 text-left ml-2 sm:mt-0 mt-5">Liste des examens</p>
        <p v-else class="font-semibold text-sm text-gray-800 text-left ml-2 sm:mt-0 mt-5">Ajouter un examen</p>
      </div>
      <div></div>
    </div>
    <!-- Title and Buttons-->

    <!-- Container-->
    <div class="border rounded-lg py-3 h-full">

      <!--  Search input and add button     -->
      <div v-show="!addExam && selectedEpreuveType && !updateExam"
           class="inline-flex w-full px-3 justify-between items-center mb-5">
        <div class="relative">
          <el-input v-model="filter.search" placeholder="Recherche par titre"/>
          <button class="absolute right-0 top-0 my-3 mr-3"
            @click="searchExam"
          >
            <svg class="h-4 w-4 fill-current text-gray-700"
                 xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                 x="0px" y="0px"
                 viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve"
                 width="512px" height="512px">
                        <path
                            d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
                      </svg>
          </button>
        </div>
        <button @click="addExam=true"
                class="py-2 px-4 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
          Ajouter un examen
        </button>
      </div>
      <!-- End Search input and add button     -->


      <!--  Button Back-->
      <div v-show="(addExam || updateExam)"
           class="inline-flex w-full px-3 justify-between items-center mb-5">
        <button @click="addExam=false ,updateExam=false "
                class="inline-flex items-center py-2 px-4 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
          <el-icon color="#fff" :size="20">
            <back/>
          </el-icon>
        </button>
        <div></div>
      </div>
      <!--  End Button Back-->

      <!--  Loader list exam    -->
      <div :class="isLoadingExams ? 'block' : 'hidden'" class="flex items-center justify-center sm:mt-20 w-full">
        <!--<div class="fulfilling-square-spinner sm:mt-2">
          <div class="spinner-inner"></div>
        </div>-->
        <div class="loading">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
      <!--  Loader list exam    -->


      <!-- Exams  List  -->
      <div :class="isLoadingExams ? 'hidden' : 'block'">

        <!-- Exams  List Empty -->
        <div v-if="exams.data.length == 0 && !addExam">
          <div class="flex justify-center items-center h-full p-5 "
               :class="'sm:mt-10'">
            <el-result
                icon="info"
                :title="'Aucun examen'"
                :subTitle="'Il y a aucun examen pour ce module'"
            >
            </el-result>
          </div>
        </div>
        <!-- End Exams  List Empty -->

        <div v-else>
          <div class="mx-4">
            <div v-if="!updateExam">
              <div v-if="!addExam">
                <ExamsList :exams="exams.data"
                           @selected-exam="openDeleteExam"
                           @update-exam="openUpdateExam"

                           :lastp="exams.last_page"
                           :currentp="exams.current_page"
                           :nextPage="nextPage"
                           :prevPage="prevPage"
                           :refine = "setPage"


                />
              </div>

              <div v-else>
                <AddExamQuestions :epreuveTypeId="epreuveTypeId"
                                  :resetAddExam="resetAddExam"
                                  @add-succes="succesAdd"/>
              </div>
            </div>

            <div v-else>
              <UpdateResidanatExamQuestions :selectedModuleId="selectedModuleId" :SelectedExamData="examToUpdate"
                                   :resetupdateExam="resetupdateExam" @update-succes="succesUpdate"/>
            </div>

          </div>
        </div>


      </div>
      <!-- Exams List     -->

    </div>
    <!--  Container   -->
  </div>


  <div v-if="examToDelete">
    <DeleteExamModal :SelectedExamData="examToDelete" :closeDeleteExam="closeDeleteExam"
                     :closeDeleteExamWithDelete="closeDeleteExamWithDelete"/>
  </div>

</template>
<script>
import axios from "axios";
import {ElMessage} from "element-plus";
import AddExamQuestions from "./AddExamQuestions.vue";
import UpdateResidanatExamQuestions from "./UpdateResidanatExamQuestions.vue";
import ExamsList from "../../../ExamsList.vue";
import DeleteExamModal from "./DeleteExamModal.vue";
import {Back} from '@element-plus/icons';
export default {
  components: {DeleteExamModal, ExamsList, UpdateResidanatExamQuestions, AddExamQuestions, Back},
  data() {
    return {
      addExam: false,
      updateExam: false,
      // List exams
      isLoadingExams: false,
      exams: {
        data: []
      },

      filter: {
        page: 1,
        search: ""
      },

      // epreuve
      epreuveTypeId: null,

      // Delete exam
      examToDelete: null,

      // update exam
      examToUpdate: null
    }
  },
  computed: {
    userToken() {
      return this.$store.getters.get_token;
    }
  },
  methods: {

    loadExams(typeId) {
      this.addExam = false;
      this.isLoadingExams = true;
      this.epreuveTypeId = typeId;
      axios.get(process.env.VUE_APP_API_BASE_URL + "/residanat/exams/" + typeId, {
        params: {
          ...this.filter
        },
        headers: {
          Authorization: "Bearer" + this.userToken,
        },
      })
          .then(({data}) => {
            this.exams = data.examens;
            this.isLoadingExams = false;
          })
          .catch(() => {
            this.error_msg();
            this.isLoadingExams = true;
          });
    },
    nextPage(){
      if(this.filter.page < this.exams.last_page){
        this.setPage(this.filter.page + 1);
      }
    },
    prevPage(){
      if(this.filter.page > 1){
        this.setPage(this.filter.page - 1);
      }
    },
    setPage(page){
      this.filter.page = page;
      this.loadExams(this.epreuveTypeId);
    },
    searchExam(){
      this.filter.page=1;
      this.loadExams(this.epreuveTypeId);
    },
    openDeleteExam(exam) {
      this.examToDelete = exam;
    },

    closeDeleteExam(){
      this.examToDelete = null;
    },
    closeDeleteExamWithDelete(){
      this.exams.data = this.exams.data.filter((tab) => tab.id !== this.examToDelete.id);
      this.examToDelete = null;
      this.succesDelete();
    },

    resetAddExam(){
      this.addExam = false;
    },

    openUpdateExam(examen){
      this.updateExam = true;
      this.examToUpdate = examen;

    },
    resetupdateExam() {
      this.updateExam = false;
    },


    ///////////// notifications
    error_msg() {
      ElMessage.error('Une erreur est survenue !')
    },
    succesDelete() {
      this.loadExams(this.epreuveTypeId);
      ElMessage({
        message: "L'examen a été supprimé avec succès.",
      })
    },
    succesAdd(){

      this.loadExams(this.epreuveTypeId);
      ElMessage({
        message: "L'examen a été ajouté avec succès.",
        type: 'success',
      })
    }
  },
  props: {
    selectedEpreuveType: Object,
  },
}
</script>