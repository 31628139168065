<template>
  <div>
    <div class="fixed inset-0 z-10 bg-red-200" style="opacity: 0.5" aria-hidden="true"></div>
    <!--   delete modal   -->
    <div class="z-50 inset-0 fixed flex justify-center items-center">
      <div class=" flex flex-col w-16/12 sm:w-5/6 lg:w-1/2 max-w-md mx-auto rounded-lg border border-gray-300 shadow-xl">
        <div class=" flex flex-row justify-between items-center px-3 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
          <p class="font-semibold text-sm text-gray-800">Supprimer un examen</p>
          <svg @click="closeDeleteExam" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </div>
        <div class="flex flex-col px-6 py-5 bg-gray-50">
          <div class=" flex flex-col sm:flex-row items-center text-center text-sm p-3 sm:space-x-5">
            <div class="w-full">
              Voulez vous vraiment supprimer l'examen<br>
              <span class="font-semibold">{{SelectedExamData.name}} </span>
              de votre liste d'examens.
            </div>
          </div>
        </div>
        <div
            class=" flex flex-row items-center justify-between px-6 py-3 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg "
        >
          <button
              @click="closeDeleteExam"
              class="font-semibold text-gray-600 text-sm"
          >
            Annuler
          </button>
          <button @click="deleteExam(SelectedExamData.id)" class=" text-sm px-4 text-red-400 font-semibold bg-white rounded"
          >
            Supprimer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import { ElMessage } from 'element-plus';
export default {

  props:{
    SelectedExamData:Object,
    closeDeleteExam:Function,
    closeDeleteExamWithDelete:Function
  },
  methods: {
    deleteExam(id){
      axios
          .delete(process.env.VUE_APP_API_BASE_URL+"/residanat/exams/"+id, {
            headers: {
              Authorization: "Bearer" +  this.$store.getters.get_token,
            },
          })
          .then(() => {
            this.closeDeleteExamWithDelete();
          })
          .catch(() => {
            this.errorm();
            this.isLoading = false;
          });

    },
  },
  setup(){
    const errorm = () => {
      ElMessage.error('Une erreur est survenue !')
    }

    return{errorm}
  }
};
</script>